export default defineNuxtRouteMiddleware(async (to) => {
    const teamIndexPage = `/admin/${to.params.team}`;

    const productIndexPage = `${teamIndexPage}/${to.params.product}`;

    const {
        manageableProducts,
        canManageProducts,
        checkProductManagementAccess,
    } = useUserPermissions();

    if (to.path === teamIndexPage && canManageProducts.value) {
        await navigateTo(`${teamIndexPage}/${manageableProducts[0]}`);
        return;
    }

    if (
        checkProductManagementAccess(to.params.product) &&
        to.path === productIndexPage
    ) {
        await navigateTo(productIndexPage + '/dashboard');
        return;
    }

    if (
        !checkProductManagementAccess(to.params.product) &&
        to.path !== productIndexPage
    ) {
        await navigateTo(teamIndexPage);
        return;
    }
});
